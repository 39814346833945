export default function getNotEmptyOne(contact) {

    if (contact.contact_discord) {
        return {
            contactType: 'discord',
            contactDetail: contact.contact_discord
        }
    } else if (contact.contact_email) {
        return {
            contactType: 'email',
            contactDetail: contact.contact_email
        }
    }
    else if (contact.contact_wechat) {
        return {
            contactType: 'wechat',
            contactDetail: contact.contact_wechat
        }
    }
    else {
        if(contact.contactType){
            return contact;
        }
        else if (contact.others[0] && contact.others[0].contactType) {
            return contact.others[0];
        }
        else if (contact.others[0] && contact.others[0].contact_other) {
            return {
                contactType: contact.others[0].contact_otherType,
                contactDetail: contact.others[0].contact_other
            }
        }
        else {
            return {
                contactType: 'discord',
                contactDetail: ''
            }
        }
    }
}