const typeImageCache = {
    discord: require("../assets/discord.png"),
    email: require("../assets/email.png"),
    wechat: require("../assets/wechat.png"),
    others: require("../assets/other.png"),
};
const btnShowMixin = {
    data() {
        return {
            typeImageCache: typeImageCache
        }
    },
    methods: {
        handleShowBtn(type, event) {
            let isShow = false;
            switch (type) {
                case 'claim':
                    isShow = (event.status == 0 && this.userInfo && event.postUser.id != this.userInfo.id && !event.userApplied);
                    break;
                case 'cancelclaim':
                    isShow = event.status == 0 &&
                        this.userInfo && event.postUser &&
                        event.postUser.id != this.userInfo.id
                        && event.userApplied //并且当前用户在申领名单里
                    break;
                case 'cancel':
                    isShow = event.status == 1 &&
                        this.userInfo &&
                        event.postUser &&
                        this.userInfo.id == event.postUser.id;
                    break;
                case 'receiveUser':
                    isShow = this.userInfo && event.receiveUser && this.userInfo.id == event.receiveUser.id;
                    break;
                case 'accept':
                    isShow = event.status == 1
                    break;
                case 'deny':
                    isShow = event.status == 1
                    break;
                case 'accept2':
                    isShow = event.status == 3
                    break;
                case 'deny2':
                    isShow = event.status == 2
                    break;
                case 'contact':
                    isShow = this.userInfo &&
                        (event.postUser && event.postUser.contact &&
                            this.userInfo.id == event.postUser.id) ||
                        (event.receiveUser && event.receiveUser.contact &&
                            this.userInfo.id == event.receiveUser.id);
                    break;
                case 'edit':
                    isShow = ([0, 1, 3, 4].includes(event.status)) &&
                        this.userInfo &&
                        this.userInfo.id == event.postUser.id
                    break;
                case 'delete':
                    isShow = ([0, 3, 4].includes(event.status)) &&
                        this.userInfo &&
                        this.userInfo.id == event.postUser.id
                    break;
                case 'complete':
                    isShow = event.status == 2 && this.userInfo && this.userInfo.id == event.postUser.id;
                    break;
                case 'received':
                    isShow = event &&
                        event.status == 5 &&
                        this.userInfo &&
                        this.userInfo.id == event.receiveUser.id
                    break;
                case 'review':
                    isShow = (event &&
                        event.status == 5 &&
                        this.userInfo &&
                        this.userInfo.id == event.postUser.id) ||
                        (event &&
                            event.status == 6 &&
                            this.userInfo &&
                            event.postUser &&
                            event.receiveUser &&
                            (this.userInfo.id == event.postUser.id ||
                                this.userInfo.id == event.receiveUser.id))
                    // isShow = (event &&
                    //     (event.status == 5 || event.status == 6) &&
                    //     this.userInfo &&
                    //     this.userInfo.id == event.postUser.id) ||
                    //     (event &&  this.userInfo && event.receiveUser && this.userInfo.id == event.receiveUser.id)
                    break;
                case 'claimcount':
                    isShow = event &&
                        !event.receiveUser.id;
                    break;
                case 'disableDate':
                    isShow = !(event.status < 2 && (event.postUser && this.userInfo && this.userInfo.id && this.userInfo.id == event.postUser.id))
                    break;
                default:
                    break;
            }
            return isShow
        }
    }
}

export default btnShowMixin