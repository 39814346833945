const imagesCache = {
    1: require("@/assets/avatar1.png"),
    2: require("@/assets/avatar2.png"),
    3: require("@/assets/avatar3.png"),
};
const avatarDefault = require("@/assets/avatar_default.png");

const statusClass = ["status-waitclaim",
    "status-sended",
    "status-accepted",
    "status-deny",
    "status-cancel",
    "status-completed",
    "status-closed"
]

const statusNameMap_zh = {
    0: "已发布，待申请",
    1: "等待接受",
    2: "已接受",
    3: "已拒绝",
    4: "已取消",
    5: "等待支付",
    6: "已支付并结束",
};
const statusNameMap_en = {
    0: "Waiting to be claimed",
    1: "Waiting to be accepted",
    2: "Accepted",
    3: "Denied",
    4: "Canceled",
    5: "Waiting for payment",
    6: "Paid and closed ",
};


export { imagesCache, avatarDefault, statusClass, statusNameMap_zh, statusNameMap_en };