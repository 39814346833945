import {
    imagesCache,
    avatarDefault
} from "@data/avatar.js";
import {
    applyEvent,
    deleteApplication,
    rejectEvent,
    acceptEvent,
    cancelEvent,
    deleteEvent,
    completeEvent,
    paidEvent,
} from "@/api/public.js";
import { mapGetters, mapMutations } from "vuex";
import getNotEmptyOne from "@/libs/dataPreDeal.js"

const moduleMethodMixin = {
    data() {
        return {
            denyEvent: {},
            acceptEvent: {},
            claimEvent: {}
        }
    },
    watch: {
        denyTrigger() {
            if (this.denyEvent.id) {
                this.handleDenyEvent();
            }
        },
        contactTrigger() {
            if (this.acceptEvent.id) {
                this.handleAcceptEvent();
            }
        },
        claimContactTrigger() {
            if (this.claimEvent.id) {
                this.handleClaimEvent();
            }
        }
    },
    computed: {
        ...mapGetters([
            "userInfo",
            "denyReason",
            "contact",
            "contactTrigger",
            "claimContactTrigger",
            "denyTrigger"
        ])
    },
    methods: {
        ...mapMutations([
            "setNewEventDialog",
            "setEditEventDialog",
            "setReadOnlyEventDialog",
            "setInfoCollectDialog",
            "setScenesloading",
            "setModuleDialog",
        ]),
        /**
     * 领取任务
     */
        async handleClaimEvent() {
            let vm = this;
            try {
                let res = await applyEvent({
                    eventId: vm.claimEvent.id,
                    contact: vm.contact ? vm.contact : "",
                });
                if (res.code == 200) {
                    this.$message.success(res.message);
                } else if (res.message) {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * 取消申请
         */
        async handleCancelClaimEvent(event) {
            try {
                let res = await deleteApplication({
                    eventId: event.id,
                });
                if (res.code == 200) {
                    this.$message.success(res.message);
                } else if (res.message) {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
     * 拒绝
     */
        async handleDenyEvent() {
            let vm = this;
            try {
                let res = await rejectEvent({
                    eventId: vm.denyEvent.id,
                    reason: vm.denyReason ? vm.denyReason : "",
                });
                if (res.code == 200) {
                    if (this.moduleEventId) {
                        this.getEventModule()
                    }
                    else if (this.upload_img) {
                        this.initEvents();
                        this.initMessages();
                    }
                    else {
                        this.initList();
                    }
                } else if (res.message) {
                    vm.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * 接受
         */
        async handleAcceptEvent() {
            let vm = this;
            try {
                let res = await acceptEvent({
                    eventId: vm.acceptEvent.id,
                    contact: vm.contact ? vm.contact : "",
                });
                if (res.code == 200) {
                    if (this.moduleEventId) {
                        this.getEventModule()
                    }
                    else if (this.upload_img) {
                        this.initEvents();
                        this.initMessages();
                    }
                    else {
                        this.initList();
                    }
                } else if (res.message) {
                    vm.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * A 取消 event
         */
        async handleCancelEvent(event) {
            try {
                let res = await cancelEvent({
                    eventId: event.id,
                    reason: "",
                });
                if (res.code == 200) {
                    this.$set(event, "status", 4);
                    this.$message.success(res.message);
                    this.initList();
                } else if (res.message) {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * A 删除 event
         */
        async handleDeleteEvent(event) {
            this.$confirm("删除此邀请?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    try {
                        let res = await deleteEvent({
                            eventId: event.id,
                        });
                        if (res.code == 200) {
                            this.$set(event, "status", 3);
                            this.$message.success(res.message);
                            this.initList();
                        } else if (res.message) {
                            this.$message.warning(res.message);
                        }
                    } catch (error) {
                        if (error.message) {
                            this.$message.error(error.message);
                        }
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        /**
         * A 认为 B 完成 event
         */
        async handleCompleteEvent(event) {
            try {
                let res = await completeEvent({
                    eventId: event.id,
                });
                if (res.code == 200) {
                    this.$set(event, "status", 5);
                    this.$message.success(res.message);
                    this.initList();
                } else if (res.message) {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * B 确认收到酬金
         */
        async handleEndEvent(event) {
            try {
                let res = await paidEvent({
                    eventId: event.id,
                });
                if (res.code == 200) {
                    this.$set(event, "status", 6);
                    this.$message.success(res.message);
                    this.initList();
                } else if (res.message) {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
        * 预处理event的image和contact
        */
        preSetEvent(item) {
            if (item.postUser && item.postUser.avatar && item.postUser.avatar.length > 0) {
                item.postUser.avatar = imagesCache[item.postUser.avatar]
                    ? imagesCache[item.postUser.avatar]
                    : item.postUser.avatar;
            } else {
                item.postUser = {
                    avatar: avatarDefault,
                };
            }
            if (item.receiveUser && item.receiveUser.avatar && item.receiveUser.avatar.length > 0) {
                item.receiveUser.avatar = imagesCache[item.receiveUser.avatar]
                    ? imagesCache[item.receiveUser.avatar]
                    : item.receiveUser.avatar;
            } else {
                item.receiveUser = {
                    avatar: avatarDefault,
                };
            }

            if (
                item.postUser &&
                item.postUser.contact != "" &&
                item.postUser.contact != undefined
            ) {
                item.postUser.contact = JSON.parse(item.postUser.contact);
            } else {
                // item.postUser.contact.contactDetail = "";
                // item.postUser.contact.contactType = "";
                item.postUser.contact = {
                    contactDetail: "",
                    contactType: "",
                };
            }
            if (
                item.receiveUser &&
                item.receiveUser.contact &&
                item.receiveUser.contact != undefined
            ) {
                item.receiveUser.contact = JSON.parse(item.receiveUser.contact);
            } else {
                // item.receiveUser.contact.contactDetail = "";
                // item.receiveUser.contact.contactType = "";
                item.receiveUser.contact = {
                    contactDetail: "",
                    contactType: "",
                };
            }
            if (!item.receiverContact && item.receiverContact.length == 0) {
                item.receiverContact = item.receiveUser.contact;
            }
            else {
                if (item.receiverContact && item.receiverContact != "" && item.receiverContact != "未填写") {
                    let contact = JSON.parse(item.receiverContact);
                    item.receiverContact = getNotEmptyOne(contact);
                }
                else {
                    item.receiverContact = item.receiveUser.contact;
                }
            }
            if (!item.postUserContact && item.postUserContact.length == 0) {
                item.postUserContact = item.postUser.contact;
            }
            else {
                if (item.postUserContact && item.postUserContact != "" && item.postUserContact != "未填写") {
                    let contact = JSON.parse(item.postUserContact);
                    item.postUserContact = getNotEmptyOne(contact);
                }
                else {
                    item.postUserContact = item.postUser.contact;
                }
            }
        },
        /**
        * 弹窗收集申领人联系方式
        */
        collectClaimContact(event) {
            this.claimEvent = event;
            this.setInfoCollectDialog([true, "claimContact"]);
        },
        /**
        * 弹窗收集拒绝原因
        */
        collectDenyReason(event) {
            this.denyEvent = event;
            this.setInfoCollectDialog([true, "denyReason"]);
        },
        /**
         * 弹窗收集接受原因
         */
        collectAcceptContact(event) {
            this.acceptEvent = event;
            this.setInfoCollectDialog([true, "contact"]);
        },
        /**
         * 打开编辑弹窗
         */
        handleEdit(event) {
            this.setEditEventDialog([true, event.id]);
            this.setModuleDialog([false, null]);
        },
        /**
         * 打开只读弹窗
         */
        handleReadEvent(eventId) {
            this.setReadOnlyEventDialog([true, eventId]);
        },
        /**
         * 用于切换“接受”，“不感兴趣”按钮
         */
        changeToAccept(event) {
            this.$set(event, "isShowNotInterestSelect", false);
            this.$set(event, "isShowAcceptedSelect", true);
        },
        /**
        * 用于切换“接受”，“不感兴趣”按钮
        */
        changeToNotInterest(event) {
            this.$set(event, "isShowNotInterestSelect", true);
            this.$set(event, "isShowAcceptedSelect", false);
        },
        /**
        * 用于初始化“接受”，“不感兴趣”按钮
        */
        initChangeTo(event) {
            this.$set(event, "isShowAcceptedSelect", false);
            this.$set(event, "isShowNotInterestSelect", false);
        },
    }
}

export default moduleMethodMixin