import router from "../router";

export default function toLogin(push) {
  const { path } = router.currentRoute;
  if (path !== "/coopList_wap") {
    push
      ? router.push({ path: "/coopList_wap" })
      : router.replace({ path: "/coopList_wap" });
  }
}
