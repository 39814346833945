import request from "@utils/request";
/**
 * 登录
 */
export async function login(data) {
    return request.post("/auth/login", data);
}

/**
 * 更新用户信息
 */
export async function updataUser(data) {
    return request.post("/user", data);
}

/**
 * 获取用户列表
 */
export async function matchUsers(data) {
    return request.get("/users", data);
}

/**
 * 获取用户详情
 */
export async function getUserInfo(data) {
    return request.get("/user", data);
}

/**
 * 获取所有任务
 */
export async function getAllEvents(data) {
    return request.get("/events/all", data);
}

/**
 * 发布任务
 */
export async function createEvent(data) {
    return request.post("/event", data);
}

/**
 * 编辑任务
 */
export async function editEvent(data) {
    return request.put("/event", data);
}

/**
 * 获取任务详情
 */
export async function getEventDetail(data) {
    return request.get("/event", data);
}

/**
 * 获取与user有关的的所有事件列表
 */
export async function getUserAllEvents(data) {
    return request.get("/events/user-all", data);
}

/**
 * 获取我发布的事件列表
 */
export async function getMyEvents(data) {
    return request.get("/events/mine", data);
}

/**
 * 获取邀请我的事件
 */
export async function getMyInvitations(data) {
    return request.get("/events/my-invitation", data);
}

/**
 * 获取我的消息列表
 */
export async function getMyMessages(data) {
    return request.get("/messages", data);
}

/**
 * 取消任务
 */
 export async function readMessage(data) {
    return request.post("/message/read", data);
}

/**
 * 取消任务
 */
export async function cancelEvent(data) {
    return request.post("/event/cancel", data);
}

/**
 * 接受任务
 */
export async function acceptEvent(data) {
    return request.post("/event/accept", data);
}

/**
 * 拒绝任务
 */
export async function rejectEvent(data) {
    return request.post("/event/reject", data);
}

/**
 * 删除任务
 */
export async function deleteEvent(data) {
    return request.delete("/event", data);
}

/**
 * 发布者标记任务完成
 */
export async function completeEvent(data) {
    return request.post("/event/complete", data);
}

/**
 * 被邀请者确认已支付
 */
export async function paidEvent(data) {
    return request.post("/event/paid", data);
}

/**
 * 留言
 */
export async function leaveMessage(data) {
    return request.post("/event/leave-message", data);
}

/**
 * 获取事件的留言列表
 */
export async function getEventComments(data) {
    return request.get("/event-messages", data);
}

/**
 * 申请事件
 */
export async function applyEvent(data) {
    return request.post("/event/apply", data);
}

/**
 * 取消申请
 */
export async function deleteApplication(data) {
    return request.delete("/application", data);
}

/**
 * 查看事件的申请
 */
export async function getEventApplications(data) {
    return request.get("/event-applications", data);
}

/**
* A选择通过某个申请
*/
export async function passApplication(data) {
    return request.post("/application/pass", data);
}

/**
* 上传文件
*/
export async function uploadImg(data) {
    return request.post("/common/upload", data);
}
